import { auto } from "@popperjs/core";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination]);
Swiper.use([Autoplay]);

let swiperHero = new Swiper(".hero__slider", {
  loop: false,
  grabCursor: false,
  slidesPerView: 1,
  autoplay: true,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

let swiperReviews = new Swiper(".reviews__slider", {
  loop: false,
  grabCursor: true,
  slidesPerView: 2,
  spaceBetween: 9,
  autoplay: true,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  navigation: {
    prevEl: ".reviews__prev",
    nextEl: ".reviews__next",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    1175: {
      slidesPerView: 1,
    },
    1176: {
      slidesPerView: 2,
      spaceBetween: 9,
    },
  },
});
