const btns = document.querySelectorAll(".btno");
const btn = document.querySelector(".btnv");
const btnc = document.querySelector(".btnc");
const modalOverlay = document.querySelector(".modal-overlay ");
const modals = document.querySelectorAll(".modal");
const body = document.querySelector(".page__body");

btns.forEach((el) => {
  el.addEventListener("click", (e) => {
    let path = e.currentTarget.getAttribute("data-path");

    modals.forEach((el) => {
      el.classList.remove("modal--visible");
    });

    document
      .querySelector(`[data-target="${path}"]`)
      .classList.add("modal--visible");
    modalOverlay.classList.add("modal-overlay--visible");
    body.classList.add("overflow");
  });
});

btn.addEventListener("click", (e) => {
  if (e.target == btn) {
    modalOverlay.classList.remove("modal-overlay--visible");
    body.classList.remove("overflow");
    modals.forEach((el) => {
      el.classList.remove("modal--visible");
    });
  }
});

btnc.addEventListener("click", (e) => {
  if (e.target == btnc) {
    modalOverlay.classList.remove("modal-overlay--visible");
    body.classList.remove("overflow");
    modals.forEach((el) => {
      el.classList.remove("modal--visible");
    });
  }
});

modalOverlay.addEventListener("click", (e) => {
  if (e.target == modalOverlay) {
    modalOverlay.classList.remove("modal-overlay--visible");
    body.classList.remove("overflow");
    modals.forEach((el) => {
      el.classList.remove("modal--visible");
    });
  }
});
