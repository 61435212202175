$(".mobile div").on("click", () => {
  $(".mobile div").toggleClass("active");
  $(".mobile nav").toggleClass("open");
  $(".mobile nav ul").toggleClass("show");
  $(".page__body").toggleClass("overflow");
});

$(".mobile .mobile__link").on("click", () => {
  $(".mobile div").removeClass("active");
  $(".mobile nav").removeClass("open");
  $(".mobile nav ul").removeClass("show");
  $(".page__body").removeClass("overflow");
});

for (let a = 1; a <= $(".mobile ul li").length; a++) {
  $(".mobile ul li:nth-child(" + a + ")").css(
    "animation-delay",
    "." + (a + 1) + "s"
  );
}
