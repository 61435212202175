import "./components/slider.js";
import "./components/accordion.js";
import "./components/scoll.min";
import "./components/mobile.js";
import "./components/telegramforms.js";
import "./components/telegramforms-2.js";
import "./components/telegramforms-3.js";
import "./components/form.js";
import "./components/modals";
import "./components/scrollreveal.min";
