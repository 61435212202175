const form = document.querySelector(".forma");
const telSelector = form.querySelector('input[type="tel"]');
const inputMask = new Inputmask("+7 (999) 999-99-99");
inputMask.mask(telSelector);

const form1 = document.querySelector(".form-c");
const telSelector1 = form1.querySelector('input[type="tel"]');
const inputMask1 = new Inputmask("+7 (999) 999-99-99");
inputMask1.mask(telSelector1);

const form2 = document.querySelector(".formb");
const telSelector2 = form2.querySelector('input[type="tel"]');
const inputMask2 = new Inputmask("+7 (999) 999-99-99");
inputMask2.mask(telSelector2);
